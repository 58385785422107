import React , { Component }from "react";
import "./TechGrid.css";
import TechItem from "../tech-item/TechItem";
import { Bash, Git, JavaScript,NodeJs,PostgreSQL,ReactIcon,
        Android, Arduino, C, CPP, CSharp, Dart, Java, Python, Flutter,
        css, HTML, MatLab, FinalCut, Lightroom, MySQL, Photoshop, PremierePro, Sketch, Illustrator,
        Tresds, Php, Audition, AfterEffects, Processing,
        Airtable, Neo4J, Assembly, PyTorch, Go, Bootstrap, Docker,
        Firebase, Flask, MaterialUI, GoogleCloud, Laravel, Slim, ReactSpring,
        Swift, TensorFlow, AWSLambda,AWSEC2, AWSS3, Figma, Unity, AI2,
        Keras, Postman, Pandas, Linux, Numpy, Apache, Nginx, Tailwind
} from "./gridImports"

function getTechImage(e) {
        if (e === "Bash") {
                return Bash
        } else if (e === "Git") {
                return Git
        } else if (e === "JavaScript") {
                return JavaScript
        } else if (e === "NodeJS") {
                return NodeJs
        } else if (e === "PostgreSQL") {
                return PostgreSQL
        } else if (e === "React") {
                return ReactIcon
        } else if (e === "Android") {
                return Android
        } else if (e === "Arduino") {
                return Arduino
        } else if (e === "C") {
                return C
        } else if (e === "C++") {
                return CPP
        } else if (e === "C#") {
                return CSharp
        } else if (e === "Dart") {
                return Dart
        } else if (e === "Java") {
                return Java
        } else if (e === "Python") {
                return Python
        } else if (e === "Flutter") {
                return Flutter
        } else if (e === "css") {
                return css
        } else if (e === "HTML") {
                return HTML
        } else if (e === "MatLab") {
                return MatLab
        } else if (e === "FinalCut Pro") {
                return FinalCut
        } else if (e === "Lightroom") {
                return Lightroom
        } else if (e === "MySQL") {
                return MySQL
        } else if (e === "Photoshop") {
                return Photoshop
        } else if (e === "Premiere Pro") {
                return PremierePro
        } else if (e === "Sketch") {
                return Sketch
        } else if (e === "Illustrator") {
                return Illustrator
        } else if (e === "Php") {
                return Php
        } else if (e === "Audition") {
                return Audition
        } else if (e === "After Effects") {
                return AfterEffects
        } else if (e === "Processing") {
                return Processing
        } else if (e === "3DS Max") {
                return Tresds
        } else if (e === "Airtable") {
                return Airtable
        } else if (e === "Neo4J") {
                return Neo4J
        } else if (e === "Assembly") {
                return Assembly
        } else if (e === "PyTorch") {
                return PyTorch
        } else if (e === "Go") {
                return Go
        } else if (e === "Bootstrap") {
                return Bootstrap
        } else if (e === "Tailwind CSS") {
                return Tailwind
        } else if (e === "Docker") {
                return Docker
        } else if (e === "Firebase") {
                return Firebase
        } else if (e === "Flask") {
                return Flask
        } else if (e === "Material UI") {
                return MaterialUI
        } else if (e === "Google Cloud") {
                return GoogleCloud
        } else if (e === "Laravel") {
                return Laravel
        } else if (e === "PHP Slim") {
                return Slim
        } else if (e === "React Spring") {
                return ReactSpring
        } else if (e === "Swift") {
                return Swift
        } else if (e === "Tensor Flow") {
                return TensorFlow
        } else if (e === "AWS Lambda") {
                return AWSLambda
        } else if (e === "PHP") {
                return Php
        } else if (e === "AWS EC2 + ECS") {
                return AWSEC2
        } else if (e === "AWS S3") {
                return AWSS3
        } else if (e === "Figma") {
                return Figma
        } else if (e === "Unity") {
                return Unity
        } else if (e === "AI2") {
                return AI2
        } else if (e === "Keras") {
                return Keras
        } else if (e === "Postman") {
                return Postman
        } else if (e === "Pandas") {
                return Pandas
        } else if (e === "Linux") {
                return Linux
        } else if (e === "Numpy") {
                return Numpy
        } else if (e === "Apache") {
                return Apache
        } else if (e === "Nginx") {
                return Nginx
        }
}

class TechGrid extends Component {

    render(){
        return (

            <div className="techGrid">
                    {this.props.hijos.map(element => {
                            return (
                                <TechItem name = {element.name} image = {getTechImage(element.name)}/>
                            )})}

            </div>

        );
    }
}

export default TechGrid;

