import React , { Component }from "react";
import "./Footer.css";


class Footer extends Component {

    render(){
        return (
            <div className="footer">
                <p className="titles">Let’s make something amazing together.</p>
                <p className="titles">Start by <a href="/contact"><u>saying hi</u></a></p>
                <div className="bottomSection">
                    <p><b>Victor</b> Xirau Guardans</p>
                    <span className="dot"></span>
                    <p>© {(new Date().getFullYear())}. All rights reserved </p>
                </div>
            </div>
        );
    }
}

export default Footer;

