import React, {
    Component

} from "react";
import "./contact.css";
import "./contact-form-css/main.css";
import TitleSection from "../../components/sections/title-section/TitleSection";
import SocialContact from "../../components/sections/social-contact/SocialContact";
import Footer from "../../components/sections/footer/Footer";



class Contact extends Component {

    render() {
        return (
            <div className="cuerpo">
                <TitleSection
                    title="contact"
                />
                <SocialContact/>
                <Footer/>

            </div>
        );
    }
}

export default Contact;