import React, {
    Component

} from "react";
import "./DCoding.css";
import TechItem from "../../../../components/tech-item/TechItem";
import {
    AWSEC2,
    Docker, FFmpeg,
    Firebase,
    Flutter,
    Nginx,
} from "../../../../components/tech-grid/gridImports";
import ProjectHeader from "../../../../components/sections/project-header/ProjectHeader";
import Footer from "../../../../components/sections/footer/Footer";
import RaisedButton from "../../../../components/raised-button/RaisedButton";

import Fade from 'react-reveal/Fade';
import DCoding from "../../../../assets/images/project-covers/Dcoding.png";


class DCodingPage extends Component {


    render() {
        return (
            <div className="cuerpo">
                <ProjectHeader
                    name={"DCoding"}
                    image={DCoding}
                    year={"2023"}
                    languages={[
                        <TechItem clau="dcodeFlutter" name="Flutter" image={Flutter}/>,
                        <TechItem clau="dcodeEC2" name="AWS EC2" image={AWSEC2}/>,
                        <TechItem clau="dcodeNginx" name="Nginx" image={Nginx}/>,
                        <TechItem clau="dcodeMpeg" name="FFmpeg" image={FFmpeg}/>,
                        <TechItem clau="dcodeDocker" name="Docker" image={Docker}/>,
                        <TechItem clau="dcodeFirebase" name="Firebase" image={Firebase}/>
                    ]}
                />
                <div className="dcHeader">
                    <p id="whatPD">What is DCoding?</p>
                    <p className="pdDescription">
                        DCoding is a cross-platform streaming app developed during my university years. It utilizes the ffmpeg plugin to facilitate video streaming, but unlike typical projects that might only interact with ffmpeg via command line, we decided to create a comprehensive user interface. This app allows for managing and viewing multiple live streams simultaneously, catering to both broadcasters using OBS or similar software and viewers seeking a seamless streaming experience. The app is built with Flutter, making it accessible on web, Android, and iOS platforms, and operates on a robust backend infrastructure hosted on an EC2 instance with an nginx server.
                    </p>
                    <p className="pdDescription">
                        To support the functionality of DCoding, our team integrated several technologies. Videos uploaded by users are stored in Cloudinary, while AWS is used for load balancing and streaming capabilities, ensuring the platform can handle multiple streams without degradation of service. Firebase serves as the backbone for user management, introducing a social aspect to the app, akin to platforms like YouTube. This integration allows users not only to stream and view content but also to interact within a community setting, enhancing the overall user experience and understanding of streaming technology.
                    </p>
                    <p id="dd2">The other members of the team were:</p>
                    <div className="linksAutors">
                        <a href="https://www.linkedin.com/in/mart%C3%AD-ejarque-galindo/" target="_blank"
                           rel="noreferrer"><u>Martí Ejarque</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/rafael-morera-jeeninga/" target="_blank"
                           rel="noreferrer"><u>Rafael
                            Morera</u></a>
                    </div>
                </div>
                <div className="middle-dc-section">
                    <Fade left>
                        <div className="dcoding-video">
                            <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/w58-ySafW9Q?si=Ro-DUOQudEX7OCdi"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </Fade>
                    <Fade right>
                        <div>
                            <p className="sectionTitle">What it does</p>
                            <p>
                                DCoding is equipped with a server designed to handle incoming video streams, which can
                                then be relayed directly to YouTube. Within the
                                app, users have the ability to watch live-streamed content as it's broadcasted or delve
                                into an archive of previous streams, which are securely stored in Cloudinary.
                            </p>
                            <p>
                                The app has a search feature, enabling users to find specific streams,
                                videos, or other users across the platform. Additionally, users interested in starting their own streams have access to a
                                streamlined process for initiating a new broadcast, complete with a profile page that
                                displays all necessary details for setting up broadcasting software like OBS.
                            </p>

                        </div>
                    </Fade>
                </div>
                <div className="finalButtons">
                    <RaisedButton
                        text="View Site"
                        url="https://dcoding-vmr.netlify.app"
                        target="_blank"
                    />
                    <RaisedButton
                        text="View Source Code"
                        url="https://github.com/vxirau/DCoding"
                        target="_blank"
                    />
                </div>

                <Footer/>
            </div>
        );
    }
}

export default DCodingPage;