import React, {
    Component

} from "react";
import "./Theatro.css";
import TechItem from "../../../../components/tech-item/TechItem";
import {
    DialogF,
    GraphQL,
    JavaScript, Neo4J,
    NodeJs,
    Python
} from "../../../../components/tech-grid/gridImports";
import ProjectHeader from "../../../../components/sections/project-header/ProjectHeader";
import Footer from "../../../../components/sections/footer/Footer";
import PhoneGallery from "../../../../components/phone-gallery/PhoneGallery";
import RaisedButton from "../../../../components/raised-button/RaisedButton";

import Arch from '../../../../assets/images/project-images/architecture.png'
import { Screen1, Screen2, Screen3  } from "./thScreens"

import Fade from 'react-reveal/Fade';
import Theatro from "../../../../assets/images/project-covers/Theatro.png";


class TheatroPage extends Component {


    render() {
        return (
            <div className="cuerpo">
                <ProjectHeader
                    name={"Theatro"}
                    image={Theatro}
                    year={"2023"}
                    languages={[
                        <TechItem clau="thPython" name="Python" image={Python}/>,
                        <TechItem clau="thJS" name="JavaScript" image={JavaScript}/>,
                        <TechItem clau="thJS" name="NodeJS" image={NodeJs}/>,
                        <TechItem clau="thJS" name="DialogFlow" image={DialogF}/>,
                        <TechItem clau="thGraph" name="GraphQL" image={GraphQL}/>,
                        <TechItem clau="thNeo" name="Neo4J" image={Neo4J}/>,
                    ]}
                />
                <div className="p-header-section">
                    <p id="whatPD">What is Theatro?</p>
                    <p className="pdDescription">Contemporary chat ChatBots are easy to use and have the ability to simulate human conversations. However, they cannot evaluate large data sets to provide a response to the user. Instead, we have many free APIs that contain this data and can offer it given a number of parameters. In this we designed a server capable of listening to DialogFlow and processing them using a Graph database to improve the user experience and provide a higher volume of information than usual to the client. Using Google Dialogflow we analyze and GraphQL translates the Cypher query to request our Neo4J database, which analyzes and provides an accurate answer using a complete dataset and simulates a human-like conversation. Use the database to provide accurate answers using a response dataset and Google Dialogflow to simulate human interactions.</p>
                    <p id="dd2">The other members of the team were:</p>
                    <div className="linksAutors">
                        <a href="https://www.linkedin.com/in/bielcarpi" target="_blank" rel="noreferrer"><u>Biel Carpi</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/rafael-morera-jeeninga/" target="_blank" rel="noreferrer"><u>Rafael Morera</u></a>
                    </div>
                </div>
                <div className="api-section">
                    <Fade left>
                        <div>
                            <p className="sectionTitle">API</p>
                            <p>The API was a big part of this. We can see their interaction cycle with this image. The user interacts with Telegram, but it will talk to DialogFlow who will also petition his webhook, hosted on Heroku for it to be publicly accessible. This server will then handle the DialogFlow request,
                                convert it to GraphQL and request our Neo4J database hosted on a VM a Google Cloud containing all the IMDB information available at the time.</p>
                        </div>
                        <img className="archImage" src={Arch} alt="map"/>
                    </Fade>
                </div>
                <PhoneGallery
                    title="Check out screenshots of the bot here!"
                    images={[Screen1, Screen2, Screen3]}
                    color="#2C2F33"
                />
                <div className="finalButtons">
                    <RaisedButton
                        text="View Source Code"
                        url="https://github.com/vxirau/Theatro"
                        target="_blank"
                    />
                    <RaisedButton
                        text="Try in Telegram"
                        url="https://t.me/TheatroBot"
                        target="_blank"
                    />
                </div>

                <Footer/>
            </div>
        );
    }
}

export default TheatroPage;