import React from "react";
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./screens/home/home";
import About from "./screens/about/about";
import Contact from "./screens/contact/contact";
import {Helmet} from "react-helmet";
import DyvidePage from "./screens/work/projects/Dyvide/Dyvide";
import KofuPage from "./screens/work/projects/kofu/Kofu";
import PlaneDodgePage from "./screens/work/projects/plane-dodge/PlaneDodge";
import SallefyPage from "./screens/work/projects/Sallefy/Sallefy";
import Work from "./screens/work/work";
import {Redirect} from "react-router";
import TheatroPage from "./screens/work/projects/Theatro/Theatro";
import ZunePage from "./screens/work/projects/Zune/Zune";
import DCodingPage from "./screens/work/projects/DCoding/DCoding";

var hasCommented=false;

function EasterEgg(){
    if(!hasCommented){
        hasCommented = true;
        document.querySelector("head").insertAdjacentHTML('beforebegin', "<!--\n" +
            "             _______________________________\n" +
            "            |  Welcome to my source code!   |\n" +
            "            |  I wish you good browsing :)  |\n" +
            "            |_______________________________|\n" +
            "              \\/\n" +
            "         ..\n" +
            "        ( '`<\n" +
            "         )(\n" +
            "  ( ----'  '.\n" +
            "  (         ;\n" +
            "   (_______,'\n" +
            "VXG ~^~^~^~^~^~^\n" +
            "-->");
    }
}

export default function MainPage() {
    return (
        <BrowserRouter>
            <Header/>
            {EasterEgg()}
            <Routes>
                <Route exact path="/" element={<><Helmet><title>Victor Xirau Guardans</title></Helmet><Home/></>} />
                <Route exact path="/work" element={<><Helmet><title>Work - Victor Xirau Guardans</title></Helmet><Work/></>} />
                <Route exact path="/about" element={<><Helmet><title>About - Victor Xirau Guardans</title></Helmet> <About /></>} />
                <Route exact path="/contact" element={<><Helmet><title>Contact - Victor Xirau Guardans</title></Helmet> <Contact /></>} />
                <Route exact path="/dyvide" element={<><Helmet><title>Dyvide - Victor Xirau Guardans</title></Helmet><DyvidePage /></>} />
                <Route exact path="/kofu" element={<><Helmet><title>Kofu - Victor Xirau Guardans</title></Helmet><KofuPage /></>} />
                <Route exact path="/plane-dodge" element={<><Helmet><title>Plane Dodge - Victor Xirau Guardans</title></Helmet><PlaneDodgePage /></>} />
                <Route exact path="/sallefy" element={<><Helmet><title>Sallefy - Victor Xirau Guardans</title></Helmet><SallefyPage /></>} />
                <Route exact path="/theatro" element={<><Helmet><title>Theatro - Victor Xirau Guardans</title></Helmet><TheatroPage /></>} />
                <Route exact path="/zune" element={<><Helmet><title>Zune - Victor Xirau Guardans</title></Helmet><ZunePage /></>} />
                <Route exact path="/dcoding" element={<><Helmet><title>DCoding - Victor Xirau Guardans</title></Helmet><DCodingPage /></>} />
                <Route exact path="/about-me" element={<><Helmet><title>Sallefy - Victor Xirau Guardans</title></Helmet><Redirect to="/about" /></>} />
            </Routes>
        </BrowserRouter>
    );
}

