import React , { Component }from "react";
import "./TechItem.css";
import Zoom from 'react-reveal/Zoom';



class TechItem extends Component {

    render(){
        return (
            <div key={this.props.clau} className="item">
                <Zoom>
                    <div className="name">{this.props.name}</div>
                    <img className="imageItemTech" src={this.props.image} alt={this.props.name}/>
                </Zoom>
            </div>
        );
    }
}

export default TechItem;

