import React , { Component }from "react";
import "./TechStack.css";
import TechGrid from "../../tech-grid/TechGrid";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TechImage from "../../../assets/images/TechStackNom.png"
import techElements from "./techGridItems"
import {Fade} from "react-reveal";

class TechStack extends Component {

    render(){
        return (
            <div className="techStack">
                <p id="stackTitle">Technology Highlights</p>
                <p id="stackDescription">Below is a showcase of my strongest skills, which I am constantly working to enhance and extend.</p>
                <div>
                    <Tabs>
                        <TabList
                            className={"tabListNames"}
                        >
                            <Tab>Technologies</Tab>
                            <span id="stackDot" className={"separatorPunto"}></span>
                            <Tab>Design Software</Tab>
                        </TabList>
                        <TabPanel className={"panelStack"} >
                            <TechGrid hijos={techElements.languages}/>
                        </TabPanel>
                        <TabPanel className={"panelStack"} >
                            <TechGrid hijos={techElements.sftware}/>
                        </TabPanel>
                    </Tabs>
                    <div className="techImage">
                        <Fade right>
                            <img className={"imatgeTechStacking"} src={TechImage} alt={"Tech Stack"}/>
                        </Fade>
                    </div>
                </div>
            </div>
        );
    }
}

export default TechStack;

