import React, {
    Component

} from "react";
import "./work.css";
import TitleSection from "../../components/sections/title-section/TitleSection";
import Footer from "../../components/sections/footer/Footer";
import TechItem from "../../components/tech-item/TechItem";
import {
    AndroidRobot,
    Android,
    CSharp,
    Java,
    GraphQL,
    Apple,
    DialogF,
    FinalCut,
    Sketch,
    Firebase,
    AWSS3,
    Unity,
    AI2,
    Spring,
    Flutter,
    NodeJs,
    Dart,
    Go,
    FFmpeg,
    AWSEC2,
    Nginx, Docker, TensorFlow, Neo4J, Python, JavaScript
} from "../../components/tech-grid/gridImports";
import Zune from "../../assets/images/project-covers/zune.jpg"
import Dyvide from "../../assets/images/project-covers/dyvide.jpg"
import Sallefy from "../../assets/images/project-covers/Sallefy.jpg"
import Kofu from "../../assets/images/project-covers/Kofu.jpg"
import PlaneDodge from "../../assets/images/project-covers/PlaneDodge.jpg"
import Theatro from "../../assets/images/project-covers/Theatro.png"
import DCoding from "../../assets/images/project-covers/Dcoding.png"
import Pinzell from "../../assets/images/icons/vectors.png"
import RaisedButton from "../../components/raised-button/RaisedButton";
import GithubRepo from "../../components/github-repo/GithubRepo";
import ProjectItem from "../../components/project-list-item/ProjectItem";
import ClickableDiv from "react-clickable-div";

class Work extends Component {
    render() {
        return (
            <div className="cuerpo">
                <TitleSection
                    title="my work"
                />
                <div className="headerInfo">
                    <p>I have engaged in numerous projects that have significantly shaped my growth as both a developer
                        and designer. Starting at a young age, I have consistently embraced diverse challenges,
                        continuously striving to expand my knowledge and skillset.</p>
                </div>
                <p className={"title"}>Past Projects</p>
                <div className="workList">
                    <ProjectItem
                        title="DCoding"
                        description="For our fourth-year university project, tasked with experimenting with ffmpeg for video streaming, we aimed higher and created a cross-platform streaming app with Flutter. It's compatible with web, Android, and iOS, running on a Docker-contained nginx server on EC2 for seamless streaming and viewing. Users could live stream via OBS, watch on our platform, and access archived streams as videos, showcasing our project's advanced functionality and broad compatibility."
                        image={DCoding}
                        languages={[
                            <TechItem clau="dcodeFlutter" name="Flutter" image={Flutter}/>,
                            <TechItem clau="dcodeEC2" name="AWS EC2" image={AWSEC2}/>,
                            <TechItem clau="dcodeNginx" name="Nginx" image={Nginx}/>,
                            <TechItem clau="dcodeMpeg" name="FFmpeg" image={FFmpeg}/>,
                            <TechItem clau="dcodeDocker" name="Docker" image={Docker}/>,
                            <TechItem clau="dcodeFirebase" name="Firebase" image={Firebase}/>
                        ]}
                        projectUrl="/dcoding"
                    />
                    <ProjectItem
                        title="Zune"
                        description="A Flutter-based app, harnesses the power of TensorFlow Object Detection and DeepAR for an innovative user experience. By scanning objects, users can assign specific actions to them—for instance, scanning a bottle allows setting an action that other users trigger upon scanning a similar bottle. Trained on the Yolo7 and COCO datasets, Zune not only identifies objects with precision but also brings them to life through AR, enabling interactive visualizations and engagements with detected objects."
                        image={Zune}
                        languages={[
                            <TechItem clau="zuneFlutter" name="Flutter" image={Flutter}/>,
                            <TechItem clau="zuneTensor" name="TensorFlow" image={TensorFlow}/>,
                            <TechItem clau="zuneFire" name="Firebase" image={Firebase}/>,
                        ]}
                        projectUrl="/zune"
                    />
                    <ProjectItem
                        title="Theatro"
                        description="Theatro is a TV Telegram Chatbot. Utilizing Google Dialogflow for natural language understanding and GraphQL for query translation, it interfaces with a Neo4J graph database. This innovative server setup not only processes user queries with unprecedented depth by tapping into extensive datasets but also enhances the conversational experience. Theatro delivers highly accurate, data-driven responses, simulating nuanced human conversations and significantly enriching user interactions."
                        image={Theatro}
                        languages={[
                            <TechItem clau="thPython" name="Python" image={Python}/>,
                            <TechItem clau="thJS" name="JavaScript" image={JavaScript}/>,
                            <TechItem clau="thJS" name="NodeJS" image={NodeJs}/>,
                            <TechItem clau="thJS" name="DialogFlow" image={DialogF}/>,
                            <TechItem clau="thGraph" name="GraphQL" image={GraphQL}/>,
                            <TechItem clau="thNeo" name="Neo4J" image={Neo4J}/>,

                        ]}
                        projectUrl="/theatro"
                    />
                    <ProjectItem
                        title="Dyvide"
                        description="Dyvide is a revolutionary new social media app that empowers young drivers to share their travel costs with their friends. With its intuitive and user-friendly interface, Dyvide makes it easy for users to see their friends' trips and create trips themselves."
                        image={Dyvide}
                        languages={[
                            <TechItem clau="dyvFlutter" name="Flutter" image={Flutter}/>,
                            <TechItem clau="dyvDart" name="Dart" image={Dart}/>,
                            <TechItem clau="dyvGo" name="Go" image={Go}/>,
                            <TechItem clau="dyvAWS" name="AWS EC2" image={AWSEC2}/>,
                            <TechItem clau="dyvSketch" name="Sketch" image={Sketch}/>
                        ]}
                        projectUrl="/dyvide"
                    />
                    <ProjectItem
                        title="Sallefy"
                        description="On my second year at university, together with a couple of friends, we developed an Android music app named Sallefy. This app was developed under the premise that we had to implement certain functionalities such as creating playlists, playing tracks and others. We wanted to take it a step further and made a full on app with much more functionalities we wanted to see implemented."
                        image={Sallefy}
                        languages={[
                            <TechItem clau="sallAndroid" name="Android" image={Android}/>,
                            <TechItem clau="sallJava" name="Java" image={Java}/>,
                            <TechItem clau="sallSketch" name="Sketch" image={Sketch}/>,
                            <TechItem clau="sallFirebase" name="Firebase" image={Firebase}/>,
                            <TechItem clau="sallSpring" name="Spring" image={Spring}/>,
                            <TechItem clau="sallAWS" name="AWS S3" image={AWSS3}/>
                        ]}
                        projectUrl="/sallefy"
                    />
                    <ProjectItem
                        title="Plane Dodge"
                        description="On October 2019 I participated in the hackathon organised by UPC university in Barcelona. In this hackathon, our team chose to face the challenge posed by Vueling Airlines, and developed a game with Unity to entertain a user whenever he would be waiting in line for a flight."
                        image={PlaneDodge}
                        languages={[
                            <TechItem clau="pdCSharp" name="C#" image={CSharp}/>,
                            <TechItem clau="pdUnity" name="Unity" image={Unity}/>,
                            <TechItem clau="pdSketch" name="Sketch" image={Sketch}/>
                        ]}
                        projectUrl="/plane-dodge"
                    />
                    <ProjectItem
                        title="Kofu"
                        description="The year 2016 was the year I participated in the MSchools App Awards organised by Mobile World Capital. Together with some class mates, we developed a social network that ended up winning first prize on Leisure and Personal Management."
                        image={Kofu}
                        languages={[
                            <TechItem clau="kofAndroid" name="Android" image={Android}/>,
                            <TechItem clau="kofJava" name="Java" image={Java}/>,
                            <TechItem clau="kofAI2" name="AI2" image={AI2}/>,
                            <TechItem clau="kofSketch" name="Sketch" image={Sketch}/>,
                            <TechItem clau="kofFCP" name="FinalCut Pro" image={FinalCut}/>
                        ]}
                        projectUrl="/kofu"
                    />
                </div>
                <div className={"noteWorthy"}>
                    <p className={"noteWorthyProjects"}>Other Noteworthy Projects</p>
                    <p>Github Repositories</p>
                    <div className="repoGrid">
                        <GithubRepo
                            nom="RummySolver"
                            descripcio="Rummy Game Solver Algorithm | Hybrid App"
                            llenguatges={["Java", "Flutter"]}
                            url="https://github.com/vxirau/RummySolver"
                        />
                        <GithubRepo
                            nom="CSLS"
                            descripcio="Graph + Trees | Dijkstra + R-Tree + BST Tree | University Project"
                            llenguatges={["Java"]}
                            url="https://github.com/vxirau/CSLS"
                        />
                        <GithubRepo
                            nom="eSportsLS"
                            descripcio="Combinatory Optimization Algorithms | Backtracking + Branch and Bound using Greedy approach | University Project"
                            llenguatges={["Java"]}
                            url="https://github.com/vxirau/eSportsLS"
                        />
                        <GithubRepo
                            nom="AgeRoyale"
                            descripcio="ClashRoyale clone made with JavaSwing"
                            llenguatges={["Java", "Swing"]}
                            url="https://github.com/vxirau/AgeRoyale"
                        />
                        <GithubRepo
                            nom="LSteam"
                            descripcio="Game Store using PHP w/ Slim & Twig"
                            llenguatges={["PHP", "Javascript", "Twig", "CSS"]}
                            url="https://github.com/vxirau/LSteam"
                        />
                        <GithubRepo
                            nom="SolarSystem"
                            descripcio="3D C++/OpenGL/GLFW Simple Solar System Model"
                            llenguatges={["C++"]}
                            url="https://github.com/vxirau/SolarSystem"
                        />
                    </div>
                    <RaisedButton
                        text="Show More"
                        url="https://github.com/vxirau?tab=repositories"
                        target="_blank"
                    />
                </div>
                <p className={"title"}>App Stores</p>
                <p style={{textAlign: 'center', marginLeft: '50px', marginRight: '50px'}}>Developer Profile on each of the Stores. Here you can check for published apps.</p>
                <div className={"developer-pages"}>
                    <ClickableDiv className="developer-pill" onClick={() => {
                        window.open("https://play.google.com/store/apps/dev?id=6551029002169843440", '_blank').focus();
                    }}>
                        <img src={AndroidRobot} alt={"play-store"}/>
                        <p>Developer Page</p>
                    </ClickableDiv>
                    <ClickableDiv className="developer-pill" onClick={() => {
                        window.open("https://apps.apple.com/es/developer/victor-xirau/id1606535429", '_blank').focus();
                    }}>
                        <img src={Apple} alt={"app-store"}/>
                        <p>Developer Page</p>
                    </ClickableDiv>

                </div>
                <div className="preEndDiv">
                    <img src={Pinzell} alt="designLogo"/>
                    <p>
                        In addition to the projects highlighted here, I have acquired a range of skills that are not
                        listed. For instance, I have independently created most, if not all, of the mockups and
                        promotional videos for each of these projects.
                    </p>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Work;