import React , { Component }from "react";
import "./RaisedButton.css";

class RaisedButton extends Component {

    render(){
        return (
            <div>
                <div className="button-82-pushable">
                    <span className="button-82-shadow"></span>
                    <span className="button-82-edge"></span>
                    <span className="button-82-front text"><a href={this.props.url} target={this.props.target}>{this.props.text}</a></span>
                </div>
            </div>
        );
    }
}

export default RaisedButton;

