import React , { Component }from "react";
import "./ProjectItem.css";
import RaisedButton from "../raised-button/RaisedButton";
import Fade from 'react-reveal/Fade';
import ClickableDiv from "react-clickable-div";

class ProjectItem extends Component {

    render(){
        return (
            <Fade big>

            <div className="projectItemBody">
                <ClickableDiv className="clickable-section" onClick={() => { window.open(this.props.projectUrl, '_self').focus(); }}>
                    <a href={this.props.projectUrl} target="_self"><img src={this.props.image} alt={this.props.title}/></a>
                    <div className="descriptionBox">
                        <p className={"projectTitle"}>{this.props.title}</p>
                        <p>{this.props.description}</p>
                    </div>
                </ClickableDiv>
                <div className="languagesStack">
                    {this.props.languages}
                </div>
                <div className="learnButton">
                    <RaisedButton
                        text="Learn More"
                        url={this.props.projectUrl}
                    />
                </div>
            </div>
        </Fade>
        );
    }
}

export default ProjectItem;

