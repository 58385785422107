import React, {
    Component

} from "react";
import "./Kofu.css";
import KofuImg from "../../../../assets/images/project-covers/Kofu.jpg";
import TechItem from "../../../../components/tech-item/TechItem";
import {AI2, Android, FinalCut, Java, Sketch} from "../../../../components/tech-grid/gridImports";
import ProjectHeader from "../../../../components/sections/project-header/ProjectHeader";
import Footer from "../../../../components/sections/footer/Footer";

import Prize from "../../../../assets/images/project-images/IMG-20160604-WA0000.jpg"
import Team1 from "../../../../assets/images/project-images/IMG-20160604-WA0019.jpg"
import Team2 from "../../../../assets/images/project-images/IMG-20160604-WA0076.jpg"
import PhoneGallery from "../../../../components/phone-gallery/PhoneGallery";
import RaisedButton from "../../../../components/raised-button/RaisedButton";


import { Screen1, Screen2, Screen3, Screen4, Screen5, Screen6, Screen7, Screen8, Screen9, Screen10,
    Screen11, Screen12, Screen13, Screen14, Screen15, Screen16, Screen17, Screen18, Screen19, Screen20, Screen21,
    Screen22, Screen23, Screen24, Screen25
} from './kofuScreens'

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


class Kofu extends Component {


    render() {
        return (
            <div className="cuerpo">
                <ProjectHeader
                    name={"Kofu"}
                    image={KofuImg}
                    year={"2016"}
                    languages={[
                        <TechItem clau = "kofAndroid" name = "Android" image = {Android}/>,
                        <TechItem clau = "kofJava" name = "Java" image = {Java}/>,
                        <TechItem clau = "kofAI2" name = "AI2" image = {AI2}/>,
                        <TechItem clau = "kofSketch" name = "Sketch" image = {Sketch}/>,
                        <TechItem clau = "kofFCP" name = "FinalCut Pro" image = {FinalCut}/>
                    ]}
                />
                <div className="kofuIntro">
                    <p id="kofuTitle">What is Kofu?</p>
                    <div className="gridKofu">
                        <p>Kofu was born in 2014 as an idea in my head, but it was just an idea. Two years later, a group of four students and I, who were in 4th ESO, decided to move this idea forward. With the app you could find anyone on their social networks just by knowing their name. It was achieved by allowing you to search for someone’s nicknames in the app, for instance; contact e-mail, instagram profile and even game platforms such as steam to be together with your friends anywhere you go. In addition, you could choose who could see all of this information by using a following system. This gave you the power to control who could see your information by simply approving or denying their follow request. Not only that but you could choose individually which social profiles you wanted to leave as public or which ones would require your permission to view.</p>
                        <div className="centerGridKofu">
                            <p>All information entered by users is 100% customizable and secure. With this concept we aimed to offer a new form of communication and unity between people. The goal was for future releases to allow the user to search not only by name but vice versa, if the pseudonym was in public they could search for the pseudonym and find out the person's identity and search for people near you.</p>
                            <Fade bottom>
                                <img src={Prize} alt="image1-prize-mschools"/>
                            </Fade>
                        </div>
                        <div>
                            <p>The best thing about this project, is that when we were developing it, we were offered the opportunity to participate in mSchools App Awards 2016 and won first prize on Leisure and personal management. You can checkout more on our involvement here.</p>
                            <p>mSchools Student Awards is a contest that recognizes the work done by students and teachers during the school year within the mSchools program and its different initiatives. The recognition of these awards is endorsed by professionals in education and mobile technologies.</p>
                        </div>
                    </div>
                    <Fade bottom>
                        <div className="imatgesDescripcio">
                            <img style={{marginRight: '10px'}} src={Team2} alt="image2-prize-mschools"/>
                            <img style={{marginLeft: '10px'}} src={Team1} alt="image3-prize-mschools"/>
                        </div>
                    </Fade>
                    <p style={{textAlign: 'center', marginBottom: '30px'}}>The other team members are:</p>
                    <div className="linksAutors">
                        <a href="https://www.linkedin.com/in/ramon-zalabardo-bosch-688019211/" target="_blank" rel="noreferrer"><u>Ramon Zalabardo</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/meritxell-balada-armengol-a90b12204/" target="_blank" rel="noreferrer"><u>Meritxell Balada</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/anna-cachadina-abello/" target="_blank" rel="noreferrer"><u>Anna Cachadiña</u></a>
                        <span className="dotAutors"></span>
                        <p>Andreu Martinez</p>
                    </div>
                </div>
                <Fade>
                    <div className="howBuilt">
                        <p id="howBuiltTitle">How is it built?</p>
                        <div className="builtColumn">
                            <p>Kofu was built through a couple iterations, take into account our programming ability at the time wasn’t quite impressive, but still we hustled to make the best version we could. This made us go through a couple designs, and various functionality implementations.</p>
                            <p>First iterations were made with App Inventor 2, we later on translated it entirely to java on Android Studio. This change came with it’s functionality and design alterations but was definitely a huge improvement.</p>
                            <p>Finally, we intended to make a web and a iOS version using php and swift, since it was only available for android, but due to various issues, the main one being we finished our school year and started classes de following year each one of us in different schools, the app was discontinued.</p>
                        </div>
                    </div>
                </Fade>
                <PhoneGallery
                    title="Check out screenshots of the app here!"
                    images={[Screen1, Screen2, Screen3, Screen4, Screen5, Screen6, Screen7, Screen8, Screen9, Screen10,
                        Screen11, Screen12, Screen13, Screen14, Screen15, Screen16, Screen17, Screen18, Screen19, Screen20, Screen21,
                        Screen22, Screen23, Screen24, Screen25]}
                    color="#25262B"
                />
                <div className="videoGrid">
                    <p id="videoDeveloped">For this project we developed a series of promotional videos that are showcased here.</p>
                    <p id="videoDevelopedSubtitle">Some of these videos are in Catalan or Spanish since they were made solely for the mSchools competition</p>
                    <div className="videoGridKofu">
                            <iframe width="500" height="315" src="https://www.youtube.com/embed/9eUJY8fjJYo"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            <iframe width="500" height="315" src="https://www.youtube.com/embed/VLrK460iWuE"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            <iframe width="500" height="315" src="https://www.youtube.com/embed/vfzI9W0cIu0"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            <iframe width="500" height="315" src="https://www.youtube.com/embed/5eqkXu3RB-k"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            <iframe width="500" height="315" src="https://www.youtube.com/embed/dJOdsCTm1ek"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            <iframe width="500" height="315" src="https://www.youtube.com/embed/a74s3T2fXyI"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                    </div>
                </div>
                <div className="finaldivKofu">
                    <Zoom cascade>
                        <p id="latestNews">Latest Kofu News</p>
                        <p id="latestNewsTitle">Kofu Project was abandoned in 2018. If you want to see more about it visit the website we made at the time.</p>
                        <RaisedButton
                            text="Take me there!"
                            url="https://kofuapp.wixsite.com/kofu"
                            target="_blank"
                        />
                    </Zoom>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Kofu;