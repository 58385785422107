import React , { Component }from "react";
import "./TitleSection.css";


class TitleSection extends Component {

    render(){
        return (
            <div className="titleSection">
                <p>{this.props.title}</p>
            </div>
        );
    }
}

export default TitleSection;

