import React , { Component }from "react";
import "./SeparatorCard.css";


class SeparatorCard extends Component {

    render(){
        return (
            <div className={this.props.class}>
                <img src={this.props.image} alt={this.props.title}/>
                <p className="cartulinaTitle">{this.props.title}</p>
                <p className="pilotes">{this.props.body}</p>
            </div>
        );
    }
}

export default SeparatorCard;

