import React , { Component }from "react";
import "./PhoneGallery.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Frame from '../../assets/images/pixelFrame.png'
import Fade from 'react-reveal/Fade';


class PhoneGallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 0
        };
    }

    next = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide + 1,
        }));
    };

    prev = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide - 1,
        }));
    };

    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
            this.setState({
                currentSlide: index,
            });
        }
    };

    render(){
        return (
            <Fade bottom cascade>
                <div className="phoneGallery" style={{backgroundColor: this.props.color}}>
                    <p id="phoneTitle">{this.props.title}</p>
                    <div className="galleryButtons">
                        <button onClick={this.prev} className="imageButton buttonL">
                        </button>
                        <div className="galleyContainer">
                            <div className="phone">
                                <Carousel
                                    dynamicHeight={false}
                                    showThumbs={false}
                                    infiniteLoop={true}
                                    swipeable={true}
                                    showStatus={false}
                                    emulateTouch={true}
                                    autoPlay={false}
                                    showArrows={false}
                                    showIndicators={false}
                                    selectedItem={this.state.currentSlide}
                                    onChange={this.updateCurrentSlide}
                                >
                                    {this.props.images.map(element => {
                                        return (
                                            <img className="imageItem" src={element} alt={element.toString()}/>
                                        )})
                                    }
                                </Carousel>
                            </div>
                            <div className="phoneFrame">
                                <img src={Frame} alt={"phoneFrame"}/>
                            </div>
                        </div>
                        <button onClick={this.next} className="imageButton buttonR">
                        </button>
                    </div>
                    <Fade>
                        <p id="currentPosition">{this.state.currentSlide+1}/{this.props.images.length}</p>
                    </Fade>
                </div>
            </Fade>
        );
    }
}

export default PhoneGallery;

