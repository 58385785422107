let timelineElements =
    {
        languages: [
                {
                        name: "C"
                },
                {
                        name: "C++"
                },
                {
                        name: "C#"
                },
                {
                        name: "Assembly"
                },
                {
                        name: "Python"
                },
                {
                        name: "Bash"
                },
                {
                        name: "JavaScript"
                },
                {
                        name: "Java"
                },
                {
                        name: "Android"
                },
                {
                        name: "Flutter"
                },
                {
                        name: "MySQL"
                },
                {
                        name: "Linux"
                },

        ],
        sftware: [
                {
                        name: "Illustrator"
                },
                {
                        name: "Photoshop"
                },
                {
                        name: "Lightroom"
                },
                {
                        name: "After Effects"
                },
                {
                        name: "Premiere Pro"
                },
                {
                        name: "FinalCut Pro"
                },
                {
                        name: "Sketch"
                },
                {
                        name: "Figma"
                },
                {
                        name: "3DS Max"
                },
                {
                        name: "Unity"
                },
        ],
    }


export default timelineElements