import React , { Component }from "react";
import "./Header.css";
import ReactGA from "react-ga";

function getName(name, path, path2){
	ReactGA.pageview(window.location.pathname + window.location.search)
	if(window.location.pathname === path || window.location.pathname === path2){
		return <span className="selected">&lt; {name} &gt;</span>
	}else{
		return <span>&nbsp;&nbsp;{name}&nbsp;&nbsp;</span>
	}
}

function burgerClick(){
	document.getElementById('burger').classList.toggle('is-open');
	document.getElementById('main-nav').classList.toggle('is-open');
	document.getElementById('headerSize').classList.toggle('mvlNavOpen');
}


class Header extends Component {


	render(){
  	return (
		  <div>
			  <nav id="navbar">
				  <div className="nav-wrapper">
					  <div className="logo">
						  <a href="/"><span className="logo-name">Victor</span> Xirau Guardans</a>
					  </div>

					  <ul id="menu">
						  <li><a href="/">{getName("Home", "/")}</a></li>
						  <li><a href="/work">{getName("My Work", "/work")}</a></li>
						  <li><a href="/about">{getName("About Me", "/about", "/about-me")}</a></li>
						  <li><a href="/contact">{getName("Contact", "/contact")}</a></li>
					  </ul>
				  </div>
			  </nav>

			  <div id="headerSize" className="mvlNav">
				  <div className="logoMVL">
					  <a href="/"><span className="logo-name">Victor</span> Xirau Guardans</a>
				  </div>
				  <button id="burger" className="open-main-nav" onClick={burgerClick}>
					  <span className="burger"></span>
				  </button>
				  <nav className="main-nav" id="main-nav">
					  <ul>
						  <li><a href="/">{getName("Home", "/")}</a></li>
						  <li><a href="/work">{getName("My Work", "/work")}</a></li>
						  <li><a href="/about">{getName("About Me", "/about")}</a></li>
						  <li><a href="/contact">{getName("Contact", "/contact")}</a></li>
					  </ul>
				  </nav>
			  </div>

		  </div>

	);
  }
}

export default Header;

