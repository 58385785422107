import React, {
    Component

} from "react";
import "./PlaneDodge.css";
import TechItem from "../../../../components/tech-item/TechItem";
import { CSharp, Sketch, Unity} from "../../../../components/tech-grid/gridImports";
import ProjectHeader from "../../../../components/sections/project-header/ProjectHeader";
import PlaneDodgeIMG from "../../../../assets/images/project-covers/PN878U1.jpg"
import Footer from "../../../../components/sections/footer/Footer";
import PhoneGallery from "../../../../components/phone-gallery/PhoneGallery";
import RaisedButton from "../../../../components/raised-button/RaisedButton";

import { Everis, EY, HP, FIB, Facebook, Itequia, UPC, McKinsey, Opentrends, HackUPC, SkyScanner, Soprasteria, RestbAI, Typeform, Vueling, Thoughtworks} from './importCompanies'
import Map1 from '../../../../assets/images/project-images/PD/mapa2.png'
import Map2 from '../../../../assets/images/project-images/PD/Menu.jpg'
import { Screen1, Screen2, Screen3, Screen4, Screen5, Screen6, Screen7  } from "./pdScreens"

import Fade from 'react-reveal/Fade';


class PlaneDodge extends Component {


    render() {
        return (
            <div className="cuerpo">
                <ProjectHeader
                    name={"Plane Dodge"}
                    image={PlaneDodgeIMG}
                    year={"2019"}
                    languages={[
                        <TechItem clau = "pdCSharp" name = "C#" image = {CSharp}/>,
                        <TechItem clau = "pdUnity"  name = "Unity" image = {Unity}/>,
                        <TechItem clau = "pdSketch" name = "Sketch" image = {Sketch}/>
                    ]}
                />
                <div className="pdHeader">
                    <p id="whatPD">What is Plane Dodge?</p>
                    <p className="pdDescription">Plane Dodge is an app developed at HackUPC 2019. As the name might suggest, HackUPC is a BarcelonaTech student Hackathon, this hackathon involved 700 hackers for 36 hours, hacking and shipping on anything and everything. All projects were welcome, mobile, hardware, web, you name it. Throughout the hackathon we had introductory talks for those of us who were first-time hackers as well as sponsor Tech Talks to help you us!</p>
                    <p className="pdDescription">There were tons of sponsors involved that we listed below. Each one of them offered a challenge to solve and a prize with it. In the case of our team, we decided to face the challenge offered by <b>Vueling:</b> "to build a boarding queue entertainment system which must be multiplayer and co-operative". Our solution to this challenge was a mobile game that allowed users to race with paper planes. We managed to make second place on this challenge. </p>
                    <p id="dd2">The other members of the team were:</p>
                    <div className="linksAutors">
                        <a href="https://www.linkedin.com/in/mart%C3%AD-ejarque-galindo/" target="_blank" rel="noreferrer"><u>Martí Ejarque</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/ramon-zalabardo-bosch-688019211/" target="_blank" rel="noreferrer"><u>Ramon Zalabardo</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/bernat-segura/" target="_blank" rel="noreferrer"><u>Bernat Segura</u></a>
                    </div>
                </div>
                <Fade>
                    <div className="companiesPics">
                        <img src={FIB} alt="fib"/>
                        <img src={UPC} alt="upc"/>
                        <img src={HackUPC} alt="hackupc"/>
                        <img src={McKinsey} alt="McKinsey"/>
                        <img src={Facebook} alt="facebook"/>
                        <img src={HP} alt="hp"/>
                        <img src={Everis} alt="everis"/>
                        <img src={Soprasteria} alt="SopraSteria"/>
                        <img src={Itequia} alt="Itequia"/>
                        <img src={Opentrends} alt="Opentrends"/>
                        <img src={SkyScanner} alt="skyscanner"/>
                        <img src={Vueling} alt="Vueling"/>
                        <img src={EY} alt="EY"/>
                        <img src={Thoughtworks} alt="Thoughtworks"/>
                        <img src={Typeform} alt="Typeform"/>
                        <img src={RestbAI} alt="Restb.ai"/>
                    </div>
                </Fade>
                <div className="inspoWhat">
                    <Fade left>
                        <div>
                            <p className="sectionTitle">Inspiration</p>
                            <p>Our inspiration for this project was simple: planes. We wanted to have a themed, simple game so that there were no age restrictions. Our game is an obstacle course similar to games like Temple Run. We wanted it to have a “real feel” so we designed 3d maps for the user to fly in that resembled reality as much as possible. </p>
                        </div>
                        <img className="desktopImg" src={Map1} alt="map"/>
                    </Fade>

                        <div className="mobilePlaneImg">
                            <Fade left>
                                <img src={Map1} alt="map"/>
                            </Fade>
                            <Fade right>
                                <img src={Map2} alt="map1"/>
                            </Fade>
                        </div>

                    <Fade right>
                        <img className="desktopImg" src={Map2} alt="map1"/>
                        <div>
                            <p className="sectionTitle">What it does</p>
                            <p>The game is simple. The player had to fly through a set of rings before time run out. He had 9 levels in singleplayer to practice, and multiplayer which allowed the user to connect with up to 3 players more. Points earned in a game allowed the user to further custom their plane with colors and trails. These designs are one of the first drafts in which we wanted the game to be more oriented to an obstacle avoidance kind of game, but due to time restrictions we changed the approach.</p>
                        </div>
                    </Fade>
                </div>
                <PhoneGallery
                    title="Check out screenshots of the app here!"
                    images={[Screen1, Screen2, Screen3, Screen4, Screen5, Screen6, Screen7]}
                    color="#2C2F33"
                />
                <Fade>
                    <div className="technologiesPD">
                        <p id="technologiesTitleMBL">Technologies</p>
                        <div className="techDescription">
                            <p>I believe technology is probably the most interesting part here. At the time we participated in this hackathon we were versed in programming languages like C, Java and Python but had never done a game with this set of requirements. Because of our will to learn more, we decided to do this game in an entirely new environment we had never used before and entirely new language: <b>Unity and C#</b>.</p>
                            <p>Throughout the course of those 36h, we learnt what we could about unity and C# and went on to creating and prototyping the final product you can see in the screenshots above. Clearly much improvement can be made but keep in mind we did all of that in 36h having learnt everything there and then.</p>
                        </div>
                        <p id="technologiesTitle">Technologies</p>
                    </div>
                </Fade>
                <div className="finalButtons">
                    <RaisedButton
                        text="View More"
                        url="https://devpost.com/software/planedodge"
                        target="_blank"
                    />
                    <RaisedButton
                        text="View Source Code"
                        url="https://github.com/vxirau/PlaneDodge"
                        target="_blank"
                    />
                    <RaisedButton
                        text="Download APK"
                        url="https://drive.google.com/file/d/1Jurgs5ZXGj7dvmCxbtFj_5aMncP-7FNm/view?usp=sharing"
                        target="_blank"
                    />
                </div>

                <Footer/>
            </div>
        );
    }
}

export default PlaneDodge;