import React, {
    Component

} from "react";
import "./Sallefy.css";
import TechItem from "../../../../components/tech-item/TechItem";
import {Android, AWSS3, Firebase, Java, Sketch, Spring} from "../../../../components/tech-grid/gridImports";
import ProjectHeader from "../../../../components/sections/project-header/ProjectHeader";
import SallefyIMG from "../../../../assets/images/project-covers/Sallefy.jpg"
import Footer from "../../../../components/sections/footer/Footer";
import PhoneGallery from "../../../../components/phone-gallery/PhoneGallery";
import RaisedButton from "../../../../components/raised-button/RaisedButton";

import {
    Screen1, Screen6, Screen3, Screen4, Screen5, Screen7, Screen8, Screen9, Screen10, Screen2,
    Screen13, Screen12, Screen14, Screen15, Screen16, Screen17, Screen18, Screen19, Screen20, Screen21, Screen22, Screen11
} from './sallefyScreens'

import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

class Sallefy extends Component {


    render() {
        return (
            <div className="cuerpo">
                <ProjectHeader
                    name={"Sallefy"}
                    image={SallefyIMG}
                    year={"2020"}
                    languages={[
                        <TechItem clau = "sallAndroid" name = "Android" image = {Android}/>,
                        <TechItem clau = "sallJava" name = "Java" image = {Java}/>,
                        <TechItem clau = "sallSketch" name = "Sketch" image = {Sketch}/>,
                        <TechItem clau = "sallFirebase" name = "Firebase" image = {Firebase}/>,
                        <TechItem clau = "sallSpring" name = "Spring" image = {Spring}/>,
                        <TechItem clau = "sallAWS" name = "AWS S3" image = {AWSS3}/>
                    ]}
                />
                <div className="firstHeader">
                    <p id="projTitle">What is Sallefy?</p>
                    <div className="sallefyGrid">
                        <p>This project is born on my second year in university. On a subject called Programming Projects we were introduced to Android for our first time. and as a final assignment we were asked to implement a music app of the likes of Spotify or SoundCloud. </p>
                        <div className="centerDivSallefy">
                            <p>To program this app we were offered a REST API developed with Spring Boot specifically for this subject. The backend implementation is open source and is available <a href="https://github.com/arnaugarcia/sallefy" target="_blank" rel="noreferrer"><u>here</u></a></p>
                            <p>The entirety of the project was structured using KanBan boards with structured issued throughout the different releases.</p>
                        </div>
                        <p>The task was the result of three incremental assignments, the <a href="https://github.com/vxirau/Android-Delivery1" target="_blank" rel="noreferrer"><u>first one</u></a> simply required the user to log in and preview a song list. The <a href="https://github.com/vxirau/Sallefy/releases/tag/V2" target="_blank" rel="noreferrer"><u>second one</u></a> was a much bigger increment in which we basically had to implement the entire app from playlists, to track playback, to UI. Finally, the <a href="https://github.com/vxirau/Sallefy" target="_blank" rel="noreferrer"><u>third one</u></a>, added things such as video support, creating deep links and sharing to chromecast, among others.</p>
                    </div>
                    <p id="dd1">This project was a group effort, and without this group it wouldn’t be what it became. My team and i decided to go way beyond what was asked for us at our university and implemented many more functionalities and design improvements. It is because of this improvements that this project stood out, and ended up being so complete.</p>
                    <p id="dd2">The other members of the team were:</p>
                    <div className="linksAutors">
                        <a href="https://www.linkedin.com/in/mart%C3%AD-ejarque-galindo/" target="_blank" rel="noreferrer"><u>Martí Ejarque</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/l%C3%ADdia-figueras-oliveros-69b675216/" target="_blank" rel="noreferrer"><u>Lídia Figueras</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/adri%C3%A0-pajares-aguilera-4a4879196/" target="_blank" rel="noreferrer"><u>Adrià Pajares</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/bernat-segura/" target="_blank" rel="noreferrer"><u>Bernat Segura</u></a>
                    </div>
                </div>
                <div className="seccio2">
                    <div className="designsMade">
                        <p>The designs were made with Sketch and the entire app was developed with Android Studio and, in the end, Sallefy implemented all of these functionalities</p>
                    </div>
                    <div className="functionalityGrid">
                        <Bounce cascade>
                            <p>User Authentication</p>
                            <p>Google Chromecast connection</p>
                            <p>Audio Playback (Online + Offline)</p>
                            <p>Playlist Creation</p>
                            <p>Playback Queue editing</p>
                            <p>Offline UI</p>
                            <p>Playlist Preview</p>
                            <p>Featured Playlists</p>
                            <p>Manage user Profile</p>
                            <p>Follow Playlists</p>
                            <p>Edit Songs</p>
                            <p>Playlist, track and user statistics</p>
                            <p>Manage Playlists (Edit + Delete)</p>
                            <p>Multiple criteria sorting</p>
                            <p>Sharing via deep link</p>
                            <p>Checkout users</p>
                            <p>Multimedia Notification</p>
                            <p>Video Playback</p>
                            <p>Follow Users</p>
                            <p>App-Wide Search</p>
                            <p>And more…</p>
                        </Bounce>
                    </div>
                </div>
                <PhoneGallery
                    title="All of these functionalities are reflected on the screenshots below"
                    images={[
                        Screen1, Screen2, Screen3, Screen4, Screen5, Screen6, Screen7, Screen8, Screen9, Screen10, Screen11,
                        Screen12, Screen13, Screen14, Screen15, Screen16, Screen17, Screen18, Screen19, Screen20, Screen21, Screen22
                    ]}
                    color="#25262B"
                />
                <Fade bottom>
                    <div className="video1">
                        <div className="videoInfo">
                            <p>As I mentioned before, our team stood out when developing the project because we went the extra mile to make it as good as we could. Our UI was reminiscent of Spotify and with our extra functionalities we surpassed all of our teacher’s expectations. It is because of this that we were asked to do a small video showcasing our progress thus far so that we could show what our second delivery looked like.</p>
                            <p>Little disclaimer, since the video was meant for the University it was recorded in Spanish.</p>
                        </div>
                        <div className="videoCenter">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Z6uJZ0kmf_g"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className="video2">
                        <div className="videoInfoMBL">
                            <p>As I mentioned before, our team stood out when developing the project because we went the extra mile to make it as good as we could. Our UI was reminiscent of Spotify and with our extra functionalities we surpassed all of our teacher’s expectations. It is because of this that we were asked to do a small video showcasing our progress thus far so that we could show what our second delivery looked like.</p>
                            <p>Little disclaimer, since the video was meant for the University it was recorded in Spanish.</p>
                        </div>
                        <div className="videoCenter">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/0X-TuXBuXYU"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                        <div className="videoInfo">
                            <p>As I mentioned before, our team stood out when developing the project because we went the extra mile to make it as good as we could. Our UI was reminiscent of Spotify and with our extra functionalities we surpassed all of our teacher’s expectations. It is because of this that we were asked to do a small video showcasing our progress thus far so that we could show what our second delivery looked like.</p>
                            <p>Little disclaimer, since the video was meant for the University it was recorded in Spanish.</p>
                        </div>
                    </div>
                </Fade>
                <p id="finalP">Both videos, together with the visuals, were made by me but recorded together with my team.</p>
                <div className="finalDiv">
                    <Fade>
                        <p>Sadly, there is no point in adding a download link for the APK. The app is no longer working since the subject has ended and keeping the backend active was a very high cost. But still, the entire source code is accessible below, together with all the previous releases:</p>
                        <RaisedButton
                            text="Source Code"
                            url="https://github.com/vxirau/Sallefy"
                            target="_blank"
                        />
                    </Fade>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Sallefy;