import React, {
    Component
} from "react";
import "./SocialContact.css";
import {  Github, LinkedIn, Twitter, Email} from './socialImports.js';

class SocialContact extends Component {

    render() {
        return (
            <div className="contenedorSocial">
                <a className="anclita" href="https://twitter.com/VXirau_" target="_blank" rel="noreferrer"><img
                    src={Twitter} alt="twitter"/></a>
                <a className="anclita" href="https://www.linkedin.com/in/victor-xirau/" target="_blank"
                   rel="noreferrer"><img src={LinkedIn} alt="linkedin"/></a>
                <a className="anclita" href="https://github.com/vxirau" target="_blank" rel="noreferrer"><img
                    src={Github} alt="github"/></a>
                <a className="anclita"
                   href="mailto:victorxirauguardans@gmail.com?subject=[victorxirau.tech]%20Contact%20Form">
                    <img src={Email} alt="email"/>
                </a>
            </div>
        );
    }
}

export default SocialContact;