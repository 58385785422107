import React , { Component }from "react";
import "./GithubRepo.css";
import FolderIcon from "../../assets/images/icons/folder.png"
import Github from "../../assets/images/icons/github.png"
import Foreign from "../../assets/images/icons/foreign.png"
import ClickableDiv from 'react-clickable-div'

class GithubRepo extends Component {

    render(){
        return (
            <ClickableDiv className="repoCard" onClick={() => { window.open(this.props.url, '_blank').focus(); }}>
                <div className="openIn">
                    <img style={{ marginRight: "15px" }} src={Github} alt={this.props.nom}/>
                    <img src={Foreign} alt={this.props.nom}/>
                </div>
                <img src={FolderIcon} alt={this.props.nom}/>
                <p className={"repoTitle"}>{this.props.nom}</p>
                <p className={"repoDescription"}>{this.props.descripcio}</p>
                <div className="filaLlenguatges">
                    {this.props.llenguatges.map(element => {
                        return (
                            <p key={this.props.nom + "-" + element}>{element}&nbsp;&nbsp;</p>
                        )})}
                </div>
            </ClickableDiv>
        );
    }
}

export default GithubRepo;

