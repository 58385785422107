import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import ReactGA from 'react-ga';


//
ReactGA.initialize('UA-213880191-1');


ReactDOM.render(
    <App />,
    document.getElementById('root')
);


reportWebVitals();
