import React , { Component }from "react";
import "./HomeHeader.css";
import Typewriter from "typewriter-effect";


class HomeHeader extends Component {

    render(){
        return (
            <div className="head">

                <p><span>Hi,</span> I’m Victor.</p>
                <p>I’m a Computer Engineer from Barcelona</p>
                <div className="adjectives">
                    <div className="triangle-bottomright"> </div>
                    <Typewriter
                        options={{
                            strings: ['Computer Engineer', 'Multimedia Engineer', 'Research Engineer', 'Web/App Developer', 'UI/UX Designer'],
                            autoStart: true,
                            delay: 75,
                            pauseFor: 1500,
                            loop: true,
                        }}
                    />
                </div>
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
            </div>
        );
    }
}

export default HomeHeader;

