import React, {
    Component

} from "react";
import "./about.css";
import TitleSection from "../../components/sections/title-section/TitleSection";
import Footer from "../../components/sections/footer/Footer";
import 'react-vertical-timeline-component/style.min.css';
import Me from "../../assets/images/me.jpg"
import RaisedButton from "../../components/raised-button/RaisedButton";
import Typewriter from "typewriter-effect";
import CV from "../../assets/CV-VictorXirau.pdf"
import Pulse from 'react-reveal/Pulse';



class About extends Component {

    render() {
        return (
            <div className="cuerpo">
                <TitleSection
                    title="about me"
                />
                <div className="separator"></div>
                <div className="imageTitle">
                    <Pulse>
                        <div className="myImage">
                            <img src={Me} alt="victorXirau"/>
                        </div>
                    </Pulse>
                    <div className="myInfo">
                        <p className="myName">Victor Xirau Guardans</p>
                        <div className="myAtributtes">
                            <Typewriter
                                options={{
                                    strings: ['Computer Engineer', 'Multimedia Engineer', 'Research Engineer', 'Web/App Developer', 'UI/UX Designer'],
                                    autoStart: true,
                                    delay: 75,
                                    pauseFor: 1500,
                                    loop: true,
                                }}
                            />
                        </div>
                        <p className="myDescription">
                            Hi, I’m Victor. I am a Computer and Multimedia Engineer keen to explore the frontiers of
                            technology. With a solid foundation in coding and interest for design, I have consistently
                            pursued projects that challenge and broaden my skills.<br/><br/>My interests span across
                            Computer Architecture, Machine Learning, Mobile App/Web development,
                            among others. By constantly updating my skills and keeping up with industry advancements, I aim to contribute to innovative research projects.
                        </p>
                    </div>
                </div>
                <p className="currently">I’m currently a Research Engineer at the <a className="currentlyLink" href="https://www.bsc.es/xirau-i-guardans-victor" target="_blank" rel="noreferrer"><u>Barcelona Supercomputing Center</u></a></p>

                <div className="downloadCV">
                    <RaisedButton
                        text={"Download CV"}
                        url={CV}
                        target={"_blank"}
                    />
                </div>
                <Footer/>
            </div>
        );
    }
}

/*
<div className="separatorLine">
                    <h1>TIMELINE</h1>
                </div>
                <div className="todayAndDot">
                    <p>TODAY</p>
                    <span className="greenDot"></span>
                </div>
                <VerticalTimeline
                    lineColor={"#65F4AD"}
                >
                    {timelineElements.map(element => {
                        return (
                            <VerticalTimelineElement
                                key={element.key}
                                date={element.date}
                                dateClassName="date"
                                contentStyle={element.courseURL.length>0 ? {backgroundColor: "#25262B", boxShadow: "0 3px 0 rgb(243 36 36 / 61%)"} : {backgroundColor: "#25262B"}}
                                contentArrowStyle={{ borderRight: '7px solid  #25262B' }}
                                className={"elementTimeline"}
                                iconStyle={{ background: '#65F4AD', height: '16px', width: '16px', marginLeft:'-8px', marginTop: '20px'}}
                            >
                                <img className={"imagenEvento"} src={getImage(element.icon)} alt={element.title}/>
                                <h3 className="vertical-timeline-element-title">
                                    {element.title}
                                </h3>
                                <h4 className="vertical-timeline-element-subtitle">
                                    {element.company}
                                </h4>
                                <h5 className="vertical-timeline-element-subtitle location">
                                    {element.location}
                                </h5>
                                <p id="description">{element.description}</p>

                                {(element.courseURL.length > 0 ?
                                        (<div className="urls">
                                            <a href={getFile(element.downloadCertificate)} download><u>Download Certificate</u></a>
                                            <span className="separatorDot"></span>
                                            <a href={element.courseURL} target="_blank" rel="noreferrer" ><u>View Course</u></a>
                                        </div>)
                                        : (<p></p>)
                                )}

                            </VerticalTimelineElement>
                        )
                    })}
                </VerticalTimeline>
*
* */

export default About;