import React, {
    Component

} from "react";
import "./home.css";
import TechStack from "../../components/sections/tech-stack/TechStack";
import HomeHeader from "../../components/sections/home-header/HomeHeader";
import SeparatorCard from "../../components/separator-card/SeparatorCard";
import Footer from "../../components/sections/footer/Footer";

import Vector from "../../assets/images/icons/vectors.png"
import Developer from "../../assets/images/icons/bracket.png"
import Chip from "../../assets/images/icons/chip.png"

class Home extends Component {

    render() {
        return (
            <div className="cuerpo">
                <HomeHeader/>
                <div className="separatorCards">
                    <SeparatorCard
                        title = "Computer Architect"
                        image = {Chip}
                        body="Ensuring optimal performance and efficiency."
                        class={"cartulina"}
                    />
                    <SeparatorCard
                        title = "Developer"
                        image = {Developer}
                        body = "Optimal, logical software solutions."
                        class={"cartulina"}
                    />
                    <SeparatorCard
                        title = "Design"
                        image = {Vector}
                        body = "Digital products with unique ideas"
                        class={"cartulina"}
                    />
                </div>
                <TechStack />

                <Footer/>
            </div>
        );
    }
}

// <LatestWork />

export default Home;