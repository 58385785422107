import React, {
    Component

} from "react";
import "./Dyvide.css";
import ProjectHeader from "../../../../components/sections/project-header/ProjectHeader";
import TechItem from "../../../../components/tech-item/TechItem";
import {AWSEC2, Dart, Flutter, Go, Sketch} from "../../../../components/tech-grid/gridImports";
import DyvideImg from "../../../../assets/images/project-covers/dyvide.jpg"
import PlayGoogle from "../../../../assets/images/social/gpDownload.png";
import AppStoreDownload from "../../../../assets/images/social/appDownload.png";
import Footer from "../../../../components/sections/footer/Footer";

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

class Dyvide extends Component {


    render() {
        return (
            <div className="cuerpo">
                <ProjectHeader
                    name={"Dyvide"}
                    image={DyvideImg}
                    year={"2022"}
                    languages={[
                        <TechItem clau = "dyvFlutter" name = "Flutter" image = {Flutter}/>,
                        <TechItem clau = "dyvDart" name = "Dart" image = {Dart}/>,
                        <TechItem clau = "dyvGo" name = "Go" image = {Go}/>,
                        <TechItem clau = "dyvAWS" name = "AWS EC2" image = {AWSEC2}/>,
                        <TechItem clau = "dyvSketch" name = "Sketch" image = {Sketch}/>
                    ]}
                />
                <div className="whyDyv">
                    <p id="titleDyv">Why Dyvide?</p>
                    <p></p>
                    <p id="whybodyLeft">The concept is born out of a personal need to calculate trip expenses with my friends. Together with a friend of mine, we did a small market research and came up with some apps that could do some of the features we needed, but none of them did all of them together and the way we wanted. It is because of this that we decided to take matters into our own hands and made Dyvide.</p>
                    <p id="whybodyRight">Dyvide aims to be your ultimate travel companion. Allowing you to calculate precisely all your trips, view the tolls on the trip, find cheapest gas stations, see what trips your friends are doing and much more.<br/><br/>It is currently still in development with many more features to come, most of them suggested by our users! </p>
                </div>

                <div className="downloadDyv">
                    <Fade left>
                        <div className="downl">
                            <p id="onlyAndroid">Available for Android and iOS</p>
                            <div className="downloadButtons">
                                <a href="https://play.google.com/store/apps/details?id=com.projectk.dyvide" target="_blank" rel="noreferrer"><img src={PlayGoogle} alt="play-download"/></a>
                                <a href="https://apps.apple.com/us/app/dyvide/id1622209712" target="_blank" rel="noreferrer"><img src={AppStoreDownload} alt="ios-download"/></a>
                            </div>
                        </div>
                    </Fade>
                </div>

                <Bounce>
                    <div className="srcCodeDyv">
                        <p>Visit the official website for the project <a href="https://dyvide.com" target="_blank" rel="noreferrer">here</a></p>
                    </div>
                </Bounce>
                <Footer/>
            </div>
        );
    }
}

export default Dyvide;