import React, {
    Component

} from "react";
import "./Zune.css";
import TechItem from "../../../../components/tech-item/TechItem";
import {Firebase, Flutter, TensorFlow } from "../../../../components/tech-grid/gridImports";
import ProjectHeader from "../../../../components/sections/project-header/ProjectHeader";
import Footer from "../../../../components/sections/footer/Footer";
import PhoneGallery from "../../../../components/phone-gallery/PhoneGallery";
import RaisedButton from "../../../../components/raised-button/RaisedButton";

import Map1 from '../../../../assets/images/project-images/ZZ/museum.jpg'
import Map2 from '../../../../assets/images/project-images/ZZ/school.jpg'
import { Screen1, Screen2, Screen3, Screen4, Screen5  } from "./zScreens"

import Fade from 'react-reveal/Fade';
import Zune from "../../../../assets/images/project-covers/zune.jpg";


class ZunePage extends Component {


    render() {
        return (
            <div className="cuerpo">
                <ProjectHeader
                    name={"Zune"}
                    image={Zune}
                    year={"2023"}
                    languages={[
                        <TechItem clau="zuneFlutter" name="Flutter" image={Flutter}/>,
                        <TechItem clau="zuneTensor" name="TensorFlow" image={TensorFlow}/>,
                        <TechItem clau="zuneFire" name="Firebase" image={Firebase}/>,
                    ]}
                />
                <div className="pdHeader">
                    <p id="whatPD">What is Zune?</p>
                    <p className="pdDescription">
                        Zune, a Flutter-based app, leverages <b>TensorFlow Object Detection</b> and <b>DeepAR</b> to create an immersive user experience. It stands out for its social interactivity, allowing users to scan real-world objects and assign actions like links or text, which then become shared experiences within its community. This feature transforms Zune into an interactive world, where users contribute to and explore a collective virtual layer of information and interaction overlayed on the physical world.
                    </p>
                    <p className="pdDescription">
                        While initially intended as an academic project, Zune's vision was to facilitate a new kind of social interaction. Users could, for example, visit a museum, scan an artwork, and see or share videos and links providing in-depth historical context or additional information, akin to a QR code but for any recognizable object.
                    </p>
                    <p id="dd2">The other members of the team were:</p>
                    <div className="linksAutors">
                        <a href="https://www.linkedin.com/in/mart%C3%AD-ejarque-galindo/" target="_blank" rel="noreferrer"><u>Martí Ejarque</u></a>
                        <span className="dotAutors"></span>
                        <a href="https://www.linkedin.com/in/rafael-morera-jeeninga/" target="_blank" rel="noreferrer"><u>Rafael
                            Morera</u></a>
                    </div>
                </div>
                <div className="inspoWhat">
                    <Fade left>
                        <div>
                            <p className="sectionTitle">Inspiration</p>
                            <p> Our inspiration for Zune came from seeing how museums and educational spaces often rely on QR codes for information sharing. We wanted to go beyond this, using technology to make learning about artworks and historical items more interactive. The idea was to allow users to scan objects and access information in a way that encourages exploration and interaction, not just passive reading. This approach aimed to make educational experiences more engaging, turning visits to museums or interacting with everyday objects into opportunities for active learning and connection.
                            </p>
                        </div>
                        <img className="desktopImg" src={Map1} alt="map"/>
                    </Fade>

                        <div className="mobilePlaneImg">
                            <Fade left>
                                <img src={Map1} alt="map"/>
                            </Fade>
                            <Fade right>
                                <img src={Map2} alt="map1"/>
                            </Fade>
                        </div>

                    <Fade right>
                        <img className="desktopImg" src={Map2} alt="map1"/>
                        <div>
                            <p className="sectionTitle">Today</p>
                            <p>Despite its potential to revolutionize how we interact with the world around us and share our discoveries, <b>the project has been discontinued</b>. Nevertheless, Zune's innovative approach to integrating social connectivity, augmented reality, and object detection outlines an interesting blueprint for the future of interactive technology.</p>
                        </div>
                    </Fade>
                </div>
                <PhoneGallery
                    title="Check out screenshots of the app here!"
                    images={[Screen1, Screen2, Screen3, Screen4, Screen5]}
                    color="#2C2F33"
                />
                <div className="finalButtons">
                    <RaisedButton
                        text="View Source Code"
                        url="https://github.com/vxirau/Zune"
                        target="_blank"
                    />
                </div>
                <Footer/>
            </div>
        );
    }
}

export default ZunePage;