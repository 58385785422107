import React , { Component }from "react";
import "./ProjectHeader.css";
import Fade from 'react-reveal/Fade';


class ProjectHeader extends Component {

    render(){
        return (
            <div>
                <div className="projectHeader">
                    <div className="projectHeaderDetails">
                        <p id="pName">{this.props.name}</p>
                        <p id="pYear">{this.props.year}</p>
                        <div className="projectStack">
                            {this.props.languages}
                        </div>
                    </div>
                    <Fade right>
                        <div className="projectHeaderImage">
                            <img src={this.props.image} alt={this.props.name}/>
                        </div>
                    </Fade>
                    <div className="projectStackMBL">
                        {this.props.languages}
                    </div>
                </div>
                <div className="lineSeparadora"></div>
            </div>

        );
    }
}

export default ProjectHeader;

